<template>
  <v-container v-if="vueVeiculos">
    <template>
      <v-row>
        <v-col cols="12">
          <PageToolbar
            :title="$tc('pages.veiculo', 2)"
            icon="directions_car"
            dark
          />
        </v-col>

        <v-col cols="12">
          <ListaVeiculos :key="componentKey" />
        </v-col>
      </v-row>
      <v-btn
        v-if="vueAddVeiculo"
        :to="{ name: 'NovoVeiculo' }"
        fixed
        right
        bottom
        fab
        dark
        color="buttons"
        :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </template>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Veiculos",
  components: {
    ListaVeiculos: () => import("./components/ListaVeiculos.vue"),
  },
  data: () => ({
    componentKey: 0,
    veiculo: {},
    dialog: false,
    loading: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueVeiculos() {
      return this.getAccess("vueVeiculos", "menu");
    },
    vueAddVeiculo() {
      return this.getAccess("vueVeiculo", "adicionar");
    },
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>

<style></style>
